@tailwind base;
@tailwind components;
@tailwind utilities;

html {
   font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
   margin: 0;
   padding: 0;
   height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.-webkit-sticky {
  position: sticky;
  position: -webkit-sticky;
}

/*#root {
   height: 100vh;
}*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

th {
  @apply p-1 font-medium tracking-wider text-center whitespace-nowrap sticky top-0 bg-theme-50;
}

td {
  @apply px-2 py-1 whitespace-nowrap;
}

/* FullCalendar Basic Style */

.fc .fc-toolbar.fc-header-toolbar {
  height: 2.5rem;
  margin: 0.5rem;
  margin-bottom: 0.5rem !important;
}
.fc .fc-toolbar-title {
  font-size: 1.2rem !important;
}
@media (min-width: 768px) {
  .fc .fc-toolbar-title {
    font-size: 1.5rem !important;
}
}
.fc .fc-view-harness {
  height: calc(100vh - 14rem) !important;
}
.fc-patient .fc .fc-view-harness {
  height: calc(100vh - 11rem) !important;
}
.fc .fc-button-primary {
  background-color: theme('colors.theme.700') !important;
  border-color: theme('colors.theme.700') !important;
}

.fc .fc-button-primary.fc-button-active {
  background-color: theme('colors.theme.600') !important;
  border-color: theme('colors.theme.600') !important;
}

.fc .fc-button-primary:hover {
  background-color: theme('colors.theme.600') !important;
  border-color: theme('colors.theme.600') !important;
}

.fc .fc-button-primary:disabled {
  background-color: theme('colors.theme.500') !important;
  border-color: theme('colors.theme.500') !important;
}

.fc .fc-button-primary:focus {
  --tw-ring-color: theme('colors.theme.500') !important;
  --tw-ring-color: theme('colors.theme.500') !important;
  box-shadow: 0 0 0 0.2rem theme('colors.theme.500')!important;
}

.fc .fc-timegrid-slot-lane:hover {
  background-color: aqua !important;
  opacity: 0.2 !important;
}
/* FullCalendar Event */
.fc-timegrid-event-short .fc-event-title {
  display: inline-block;
}

.fc-timegrid-event-short .fc-booking-type {
  display: none;
}

.fc-icon-print:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='white'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z' /%3E%3C/svg%3E");
}

/* FullCalendar Notice BUtton for patients schedule */
.fc .fc-button-primary.fc-notice-button {
  color: black;
  background-color: theme('colors.theme.100') !important;
}

.fc .fc-button-primary.fc-notice-button:hover {
  background-color: theme('colors.theme.300') !important;
}

@media print {
  @page { 
    size: A4 portrait;
  }
  html, body {
    height: auto;
    display: block;
    overflow: visible;
  }
  #root {
    transform: scale(1.0) translate(-0%, -0%);
  }
  .grid-rows-frame {
    grid-template-rows: unset;
  }
  nav {
    display: none;
  }
  .calendar-header {
    display: none;
  }
  footer {
    display: none;
  }
  .fc {
    height: auto!important;
  }
  .fc .fc-view-harness {
    height: auto!important;
  }
  .fc .fc-scrollgrid-liquid {
    height: auto!important;
  }
  .fc .fc-toolbar-chunk .fc-button {
    visibility: hidden;
  }
  .fc-toolbar-title {
    visibility: visible;
  }
  .fc .fc-scroller {
    height: auto!important;
    overflow: visible!important;
  }
}